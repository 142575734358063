/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PersonMedicalFacilityAssignment = {
  properties: {
    id: {
      type: 'string',
    },
    personId: {
      type: 'string',
      isRequired: true,
    },
    medicalFacilityId: {
      type: 'string',
      isRequired: true,
    },
    medicalFacilityCoordinatorId: {
      type: 'string',
      isRequired: true,
    },
    createdAt: {
      type: 'string',
    },
    updatedAt: {
      type: 'string',
    },
    medicalFacilityName: {
      type: 'string',
    },
  },
} as const;
