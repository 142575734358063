/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddProfessionalSpecialtiesRequest } from '../models/AddProfessionalSpecialtiesRequest';
import type { ListProfessionalAvaibleSkillMapsRequest } from '../models/ListProfessionalAvaibleSkillMapsRequest';
import type { ListProfessionalAvaibleSkillMapsResponse } from '../models/ListProfessionalAvaibleSkillMapsResponse';
import type { ListProfessionalSkillMapSubmissionsRequest } from '../models/ListProfessionalSkillMapSubmissionsRequest';
import type { ListProfessionalSkillMapSubmissionsResponse } from '../models/ListProfessionalSkillMapSubmissionsResponse';
import type { ProfessionalSkillMapSubmissionInfoRequest } from '../models/ProfessionalSkillMapSubmissionInfoRequest';
import type { ProfessionalSkillMapSubmissionInfoResponse } from '../models/ProfessionalSkillMapSubmissionInfoResponse';
import type { SaveProfessionalInfoRequest } from '../models/SaveProfessionalInfoRequest';
import type { SaveProfessionalInfoRequestV2 } from '../models/SaveProfessionalInfoRequestV2';
import type { SaveProfessionalInfoResponseData } from '../models/SaveProfessionalInfoResponseData';
import type { SaveProfessionalSkillMapSubmissionRequest } from '../models/SaveProfessionalSkillMapSubmissionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProfessionalService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Save a new person Entity along with its Professional counterpart
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public saveProfessionalInfo(
    requestBody: SaveProfessionalInfoRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: SaveProfessionalInfoResponseData;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/save-professional-info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add new specialties to a given professional
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public addProfessionalSpecialties(
    requestBody: AddProfessionalSpecialtiesRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/professional/add-specialty',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Save a new Professional Entity
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public saveProfessionalInfoV2(
    requestBody: SaveProfessionalInfoRequestV2,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/professional/save-professional-info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns a list of all professional avaiable skill maps
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public listProfessionalAvaibleSkillMaps(
    requestBody: ListProfessionalAvaibleSkillMapsRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: Array<ListProfessionalAvaibleSkillMapsResponse>;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/skill-map/list-professional-available-maps',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns a list of all professional skill map submissions
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public listProfessionalSkillMapSubmissions(
    requestBody: ListProfessionalSkillMapSubmissionsRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: Array<ListProfessionalSkillMapSubmissionsResponse>;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/skill-map/list-professional-submissions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns skill map submission information by given submission id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public professionalSkillMapSubmissionInfo(
    requestBody: ProfessionalSkillMapSubmissionInfoRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: ProfessionalSkillMapSubmissionInfoResponse;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/skill-map/get-submission-info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Saves a skill map submission information
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public saveProfessionalSkillMapSubmission(
    requestBody: SaveProfessionalSkillMapSubmissionRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/skill-map/save-submission',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

}
