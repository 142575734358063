/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MedicalFacility = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    uuid: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    clientName: {
      type: 'string',
      isRequired: true,
    },
    mailingAddress: {
      type: 'string',
      isRequired: true,
    },
    mailingList: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    externalId: {
      type: 'string',
      isRequired: true,
    },
    city: {
      type: 'string',
      isRequired: true,
    },
    uf: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'MedicalFacilityStatus',
      isRequired: true,
    },
    createdAt: {
      type: 'string',
    },
    updatedAt: {
      type: 'string',
    },
  },
} as const;
