/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SkillMapSubmissionAnswer = {
  properties: {
    answerId: {
      type: 'string',
    },
    submissionId: {
      type: 'string',
    },
    skillData: {
      type: 'Skill',
      isRequired: true,
    },
    answer: {
      type: 'boolean',
      isRequired: true,
    },
    accepted: {
      type: 'boolean',
    },
    file: {
      type: 'File',
    },
  },
} as const;
