/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SkillMapUpdateRequest = {
  properties: {
    mapId: {
      type: 'string',
      isRequired: true,
    },
    activate: {
      type: 'boolean',
      isRequired: true,
    },
    skills: {
      type: 'array',
      contains: {
        type: 'Skill',
      },
      isRequired: true,
    },
    overwrite: {
      type: 'boolean',
      isRequired: true,
    },
  },
} as const;
