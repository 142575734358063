/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AdminListSkillMapFilters = {
  properties: {
    status: {
      type: 'array',
      contains: {
        type: 'SkillMapStatus',
      },
      isNullable: true,
    },
    professionId: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    specialtyId: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    registrationType: {
      type: 'array',
      contains: {
        type: 'SkillMapRegistrationType',
      },
    },
    isDefault: {
      type: 'array',
      contains: {
        type: 'boolean',
      },
    },
  },
} as const;
