/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MedicalFacilitiesListResponseData = {
  type: 'all-of',
  contains: [{
    type: 'ListResponse',
  }, {
    properties: {
      data: {
        properties: {
          list: {
            type: 'array',
            contains: {
              type: 'MedicalFacility',
            },
            isRequired: true,
          },
          filterOptions: {
            properties: {
              clientName: {
                type: 'array',
                contains: {
                  type: 'string',
                },
                isRequired: true,
              },
              cities: {
                type: 'array',
                contains: {
                  type: 'string',
                },
                isRequired: true,
              },
              ufs: {
                type: 'array',
                contains: {
                  type: 'string',
                },
                isRequired: true,
              },
            },
            isRequired: true,
          },
        },
        isRequired: true,
      },
    },
  }],
} as const;
