/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListSkillMapsResponseData = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    mapId: {
      type: 'string',
      isRequired: true,
    },
    version: {
      type: 'number',
      isRequired: true,
    },
    title: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'SkillMapStatus',
      isRequired: true,
    },
    professionId: {
      type: 'string',
      isRequired: true,
    },
    specialtyId: {
      type: 'string',
      isRequired: true,
    },
    specialtyName: {
      type: 'string',
      isRequired: true,
    },
    registrationType: {
      type: 'SkillMapRegistrationType',
      isRequired: true,
    },
    isDefault: {
      type: 'boolean',
      isRequired: true,
    },
    updatedAt: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
