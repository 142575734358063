/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QueueApiService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Save a new person Entity along with its Professional counterpart
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public syncDoctorSpecialtiesJob(): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/queue/cron/professional/sync-doctor-specialties-job',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

}
