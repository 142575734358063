/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AdminListSkillMapSubmissionsFilters = {
  properties: {
    status: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    professionIds: {
      type: 'array',
      contains: {
        type: 'Profession',
      },
    },
    specialties: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
  },
} as const;
