/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AdminListMedicalFacilitiesFilter = {
  properties: {
    status: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    clientName: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    city: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    uf: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
  },
} as const;
