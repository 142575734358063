/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListMedicalFacilityCoordinatorRequest = {
  properties: {
    search: {
      type: 'string',
      isNullable: true,
    },
    sorting: {
      type: 'Sorting',
    },
    filters: {
      properties: {
        status: {
          type: 'array',
          contains: {
            type: 'MedicalFacilityCoordinatorStatus',
          },
          isNullable: true,
        },
      },
    },
    pagination: {
      type: 'PaginationRequest',
      isRequired: true,
    },
  },
} as const;
