/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListMedicalFacilitiesByMedicalFacilityCoordinatorRequest = {
  type: 'any-of',
  contains: [{
    properties: {
      medicalFacilityCoordinatorId: {
        type: 'string',
        isRequired: true,
      },
    },
  }, {
    properties: {
      userId: {
        type: 'string',
        isRequired: true,
      },
    },
  }],
} as const;
