/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListSkillMapsRequest = {
  properties: {
    search: {
      type: 'string',
      isRequired: true,
      isNullable: true,
    },
    sorting: {
      type: 'Sorting',
      isRequired: true,
    },
    filters: {
      type: 'AdminListSkillMapFilters',
      isRequired: true,
    },
    pagination: {
      type: 'PaginationRequest',
      isRequired: true,
    },
  },
} as const;
