/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListProfessionalSkillMapSubmissionsResponse = {
  properties: {
    mapId: {
      type: 'string',
      isRequired: true,
    },
    mapTitle: {
      type: 'string',
      isRequired: true,
    },
    version: {
      type: 'number',
      isRequired: true,
    },
    professionId: {
      type: 'Profession',
      isRequired: true,
    },
    professionalId: {
      type: 'string',
      isRequired: true,
    },
    specialtyId: {
      type: 'string',
      isRequired: true,
    },
    skills: {
      type: 'array',
      contains: {
        type: 'Skill',
      },
      isRequired: true,
    },
    submissionId: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'SkillMapSubmissionStatus',
      isRequired: true,
    },
    comment: {
      type: 'string',
      isRequired: true,
    },
    reviewerUserId: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
