/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SkillMapSubmissionEvaluationRequest = {
  properties: {
    submissionId: {
      type: 'string',
      isRequired: true,
    },
    evaluationResult: {
      type: 'any-of',
      contains: [{
        type: 'Enum',
      }, {
        type: 'Enum',
      }],
      isRequired: true,
    },
    comment: {
      type: 'string',
    },
    reviewer: {
      type: 'string',
      isRequired: true,
    },
    answers: {
      type: 'array',
      contains: {
        type: 'SkillMapSubmissionAnswer',
      },
      isRequired: true,
    },
  },
} as const;
