/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Person = {
  properties: {
    id: {
      type: 'string',
    },
    uuid: {
      type: 'string',
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    birthDate: {
      type: 'string',
      isRequired: true,
    },
    gender: {
      type: 'Gender',
      isRequired: true,
    },
    maritalStatus: {
      type: 'MaritalStatus',
      isRequired: true,
    },
    rg: {
      type: 'string',
      isRequired: true,
    },
    rgState: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
      isRequired: true,
    },
    phone: {
      type: 'string',
      isRequired: true,
    },
    nationality: {
      type: 'string',
      isRequired: true,
    },
    countryOrigin: {
      type: 'string',
      isRequired: true,
    },
    approvalStatus: {
      type: 'ApprovalStepStatuses',
    },
    birthCity: {
      type: 'string',
    },
    address: {
      type: 'AddressInfo',
    },
    userId: {
      type: 'string',
    },
    digitalCertificate: {
      type: 'ProfessionalDigitalCertificate',
    },
    biometricsProvider: {
      type: 'string',
    },
    bankAccount: {
      type: 'PersonBankAccount',
    },
    bank: {
      type: 'BankInfo',
    },
    motherName: {
      type: 'string',
    },
    cns: {
      type: 'string',
    },
  },
} as const;
