/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetBackgroundCheckResponseData } from '../models/GetBackgroundCheckResponseData';
import type { GetMarketplaceBackgroundCheckRequest } from '../models/GetMarketplaceBackgroundCheckRequest';
import type { MarketplaceProfessionalExportResponseData } from '../models/MarketplaceProfessionalExportResponseData';
import type { MarketplaceProfessionalInfoRequest } from '../models/MarketplaceProfessionalInfoRequest';
import type { MarketplaceProfessionalInfoResponseData } from '../models/MarketplaceProfessionalInfoResponseData';
import type { MarketplaceProfessionalListRequest } from '../models/MarketplaceProfessionalListRequest';
import type { MarketplaceProfessionalListResponseData } from '../models/MarketplaceProfessionalListResponseData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MarketplaceApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns a list of marketplace available professionals
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketplaceProfessionalList(
    requestBody: MarketplaceProfessionalListRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: Array<MarketplaceProfessionalListResponseData>;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/marketplace/professional/list',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Server error`,
      },
    });
  }

  /**
   * Gets a marketplace professional info
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketplaceProfessionalInfo(
    requestBody: MarketplaceProfessionalInfoRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: MarketplaceProfessionalInfoResponseData;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/marketplace/professional/info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Exports a professional data
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketplaceProfessionalExport(
    requestBody: {
      professionalId: string;
    },
  ): CancelablePromise<{
    success: boolean;
    data?: MarketplaceProfessionalExportResponseData;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/marketplace/professional/export',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns the professional background check information
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketplaceProfessionalBackgroundCheck(
    requestBody: GetMarketplaceBackgroundCheckRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: GetBackgroundCheckResponseData;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/marketplace/get-professional-background-check',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Server error`,
      },
    });
  }

}
