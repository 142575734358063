/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListMedicalFacilitiesRequest = {
  properties: {
    search: {
      type: 'string',
    },
    sorting: {
      type: 'Sorting',
    },
    filters: {
      type: 'AdminListMedicalFacilitiesFilter',
    },
    pagination: {
      type: 'PaginationRequest',
    },
  },
} as const;
