/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetUserOnboardedCompanyMembersResponse = {
  properties: {
    members: {
      type: 'array',
      contains: {
        type: 'CompanyMember',
      },
      isRequired: true,
    },
  },
} as const;
