/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AssignMedicalFacilityRequest = {
  properties: {
    medicalFacilityCoordinatorId: {
      type: 'string',
      isRequired: true,
    },
    medicalFacilityIds: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
  },
} as const;
