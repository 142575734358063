/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ExportProfessionalDataResponse = {
  properties: {
    fileURL: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
