/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetSkillMapVersionsRequest = {
  properties: {
    professionId: {
      type: 'string',
      isRequired: true,
    },
    specialtyId: {
      type: 'string',
    },
  },
} as const;
