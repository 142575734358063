/**
 * Sort string elements with localeCompare. Nullish values are put at the end of the sorted array
 * @param {string} a nullish or string element
 * @param {string} b nullish or string element
 */
export function simpleStrSorter(a: string | null, b: string | null) {
  if (a === null || a === undefined) return 1;
  return a.localeCompare(b as string);
}

/**
 * Helper function to be used in combination of Array.prototype.filter
 * It is expected that all elements are of the same type.
 * @param predicate If passed, should be either string or function.
 *  When string, assumes all elements of the original array are Objects, and call the prorperty with the given name.
 *  When function, applies that function for each element and uses its return value as unique condition.
 * @return Return new array with unique values based on the given parameter.
 * First element to met unique condition is returned in the final array
 */
export function uniq<T extends Record<string, unknown> | unknown>(
  predicate?: T extends Record<string, unknown>
    ? keyof T | ((value: T, index?: number, obj?: T[]) => unknown)
    : (value: T, index?: number, obj?: T[]) => unknown,
): (value: T, index: number, obj: T[]) => boolean {
  if (
    predicate !== undefined &&
    predicate !== null &&
    !['function', 'string'].includes(typeof predicate)
  ) {
    throw TypeError('Parameter should be either predicate or string');
  }

  const pred: CallableFunction =
    typeof predicate === 'function'
      ? predicate
      : predicate
        ? (e: T) => (e instanceof Object ? e[predicate as keyof T] : undefined)
        : (e: T) => e;

  return (el1, i, arr) => arr.findIndex((el2) => pred(el1) === pred(el2)) === i;
}
