/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListProfessionalAvaibleSkillMapsResponse = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    mapId: {
      type: 'string',
      isRequired: true,
    },
    title: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'SkillMapStatus',
      isRequired: true,
    },
    professionId: {
      type: 'string',
      isRequired: true,
    },
    specialtyId: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
