import { fileToArrayBuffer, generateRandomString } from '@commons/js-utils';
import {
  ErrorCode,
  ApiClient as OnboardingAPIV2,
  type Response,
} from '@medsimples/doctor-onboarding-openapi-v2';
import { DoctorOnboardingAPI } from '@medsimples/doctor-onboarding-openapi/dist/client';
import { Configuration, FrontendApi } from '@ory/client';
import { edgeConfig } from '@ory/integrations/next';
import * as E from 'fp-ts/Either';
import { getContentPath } from './utils/paths';

/**
 * @deprecated Avoid using v1 client as it does not throw errors on not ok http status
 */
const doctorOnboardingAPI = new DoctorOnboardingAPI({
  BASE: '/api/v1',
});

const onboardingAPIV2Client = new OnboardingAPIV2({
  BASE: '/api/v1',
});

const oryAPI = new FrontendApi(
  new Configuration(
    process.env.APP_ENV === 'development' ||
      process.env.NODE_ENV === 'development'
      ? edgeConfig
      : {
          basePath: process.env.NEXT_PUBLIC_ORY_SDK_URL,
          baseOptions: {
            withCredentials: true,
          },
        },
  ),
);

async function uploadFiletoR2(
  r2WorkerUrl: string,
  file: File,
  folder?: string,
): Promise<string> {
  const fileArrayBuffer = await fileToArrayBuffer(file);
  const randomKey = generateRandomString(512);
  const fileName = encodeURIComponent(file.name);
  const r2Url = [r2WorkerUrl, folder, randomKey, fileName]
    .filter((s) => s)
    .join('/');
  const reqUrl = getContentPath(r2Url, file.type);
  const resp = await fetch(reqUrl, {
    method: 'PUT',
    body: fileArrayBuffer,
    headers: {
      'Content-Type': file.type,
    },
  });
  const respJson: { data: { key: string } } = await resp.json();
  return respJson.data.key;
}

const handleAPIReq = async <T extends Response>(
  fn: () => Promise<T>,
): Promise<E.Either<string, T>> => {
  try {
    const result = await fn();
    if (result?.error?.code === ErrorCode.ServerUnauthorized)
      setTimeout(() => {
        window.location.href = '/login';
      }, 100);

    if (result?.error?.code) {
      return E.left(getErrorMessage(result.error?.code));
    }
    if (!result || !result?.success)
      return E.left('Ocorreu um erro inesperado!');

    return E.right(result);
  } catch (err) {
    console.error('Unexpected error requesting', fn.name);
    return E.left('Ocorreu um erro inesperado!');
  }
};

const handleAPIReqV2 = async <T extends Response>(
  func: () => Promise<T>,
  options: { respOptions?: Parameters<typeof handleAPIRes>[1] } = {},
): Promise<E.Either<string, T>> => {
  try {
    const result = await func().catch((err) => err.body);
    return handleAPIRes(result, options.respOptions);
  } catch (err) {
    console.error('Unexpected error requesting api');
    return E.left('Ocorreu um erro inesperado');
  }
};

const handleAPIRes = <T extends Response>(
  result: T,
  options: { defaultErrorMsg?: string } = {},
): E.Either<string, T> => {
  if (result?.error?.code === ErrorCode.ServerUnauthorized)
    setTimeout(() => {
      window.location.href = '/login';
    }, 100);

  if (result?.error?.code) {
    return E.left(getErrorMessage(result.error.code));
  }

  if (!result || !result?.success) {
    return E.left(options.defaultErrorMsg ?? 'Ocorreu um erro inesperado!');
  }

  return E.right(result);
};

// FIXME: below are duplicated code on both this and onboardin webapp. Create an external i18n package for both apps

const errorMessages: Partial<Record<ErrorCode, string>> = {
  e000001: 'Permissão negada.',
  e000002: 'Requisição inválida.',
  e000003: 'Não autorizado.',
  e000004: 'Erro no servidor da aplicação.',
  e001001: 'O fluxo de cadastro do usuário já começou.',
  e001002: 'Não foi possível encontrar o passo para a etapa do usuário.',
  e001003: 'Passo já existente para o cadastro do usuário.',
  e001004: 'Código de verificação inválido. Tente novamente.',
  e001005: 'Falha ao realizar login, telefone não informado.',
  e001006: 'Usuário não encontrado.',
  e001007: 'Usuário inválido.',
  e001008: 'Cadastro do usuário não encontrado.',
  e001009: 'Falha ao realizar login, telefone não informado.',
  e001010: 'Erro ao buscar lista de bancos.',
  e001011:
    'Você atingiu o número máximo de requisições. Tente novamente dentro de 30 minutos.',
  e001012: 'Erro ao realizar login. Tente novamente.',
  e002001: 'Cadastro de profissional não encontrado para o médico.',
  e002002: 'Usuário não encotrado para o médico.',
  e002003: 'Não foi possível encontrar um médico com os dados informados',
  e002004:
    'Erro ao validar o CPF informado. Verifique se é um CPF válido e tente novamente',
  e002005:
    'Não foi possível relacionar o CPF com o CRM. Verifique se o seu nome está atualizado no cadastro do CRM, atualize seu cadastro e tente novamente.',
  e002006:
    'Ops! Parece que o CRM já está em uso por outro usuário. Se você é o dono dessa informação, entre em contato através do botão abaixo.',
  e002007:
    'Ops! Parece que o CPF já está em uso por outro usuário. Se você é o dono dessa informação, entre em contato através do botão abaixo.',
  e003001: 'Não foi encontrado um profissional cadastrado.',
  e003002: 'Erro ao sincronizar o profissional',
  e003003: 'Erro ao listar documentos do professional.',
  e003005:
    'Ocorreu um erro ao sincronizar os dados de compliance para o profissional.',
  e004001:
    'O usuário atual não possui cadastro de pessoa fisica, assim não sendo possível encontrar uma empresa.',
  e004002: 'Não foi possível encontrar a empresa solicitada para o usuário.',
  e004003: 'Não foi possível encontrar a empresa solicitada.',
  e004004: 'Não foi possível relacionar o CPF do usuário com a empresa.',
  e004005: 'O registro da empresa não está válido.',
  e004006: 'Erro ao buscar validação da conta de banco da empresa.',
  e004007: 'Erro ao validar a conta de banco da empresa.',
  e004008: 'Conta de banco da empresa não foi encontrada.',
  e004009:
    'Você atingiu o número máximo de requisições. Tente novamente dentro de 30 minutos.',
  e004010: 'Operação inválida.',
  e004011: 'Tipo da empresa não é aceito.',
  e004012: 'Erro ao listar documentos da empresa.',
  e004023: 'Limite de envios atingidos, tente novamente mais tarde.',
  e005001:
    'Número de telefone já cadastrado, por favor, use outro, ou entre em contato em caso de problemas.',
  e005002:
    'Email já cadastrado, por favor, use outro, ou entre em contato em caso de problemas.',
  e005003: '',
  e005008: 'CPF Inválido',
  e005011:
    'Tipo de documento não pode ser determinado pelo relatório de biometria.',
  e006001: 'Profissional não encontrado. Abortando integração com o S4/HANA.',
  e006002: 'Erro ao buscar o profissional.',
  e006003: 'Erro ao criar e expandir Business Partner',
  e006004: '',
  e006005: '',
  e006018: 'CPF em situação irregular.',
  e007001: 'Erro ao buscar score da empresa na Neoway',
  e007003:
    'Saldo insuficiente para a consulta, por favor verifique os créditos com o provedor(Neoway).',
  e017004: 'Unidade não encontrada',
  e018002: 'Preencha o CPF do Coordenador para ativá-lo',
  e018004: 'O CPF informado já se encontra em uso por outro usuário',
} as const;

const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro!';

// TODO Add the possibility to have params on error messages
export const getErrorMessage = (code: string): string => {
  return errorMessages[code] ?? DEFAULT_ERROR_MESSAGE;
};

export {
  doctorOnboardingAPI,
  onboardingAPIV2Client,
  oryAPI,
  uploadFiletoR2,
  handleAPIReq,
  handleAPIReqV2,
};
